<template>
  <el-dialog
    title="审核结果"
    :visible.sync="dialogVisible"
    custom-class="policy-import-dialog"
    width="540px"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="重开原因"
        prop="startRemark"
        v-if="auditReason && auditReason.startRemark"
        class="label-none"
      >
        <div>重开原因</div>
        <el-input
          type="textarea"
          v-model="auditReason.startRemark"
          disabled
          rows="6"
        ></el-input>
      </el-form-item>
      <el-form-item label="审核结果" prop="result">
        <el-radio-group v-model="ruleForm.result">
          <el-radio :label="0">通过</el-radio>
          <el-radio :label="1">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核结果" prop="content" class="label-none">
        <div>审核意见</div>
        <el-input type="textarea" v-model="ruleForm.content" rows="6"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    auditReason: {
      type: Object,
      default: () => { }
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        this.ruleForm = {
          content: '',
          result: 0
        };
        this.dialogVisible = v;
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      ruleForm: {
        content: '',
        result: 0
      },
      rules: {
        result: [
          { required: true, message: '请选择审核结果', trigger: 'change' },
        ]
      }
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handleImport() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$emit('submit', this.ruleForm);
        }
      });
    },
  }
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
}
</style>
<style lang="scss">
.label-none {
  .el-form-item__label {
    display: none;
  }
  .el-form-item__content {
    margin: 0 !important;
    padding-left: 32px;
  }
}
</style>